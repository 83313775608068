@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.display-mixins.scss";
@import "ui/styles/2-tools/tools.mixins.scss";
@import "ui/styles/2-tools/tools.colors.scss";

.Cluster {
	background-color: var(--color-white);
	padding: 10rem 0;
	position: relative;

	@include mq("md") {
		padding: 20rem 0;
	}
	@include mq("lg") {
		padding: 30rem 0;
	}
}
.Cluster_bgElement {
	@include coloredComponent;
	width: 100%;
	height: 50%;
	position: absolute;
	top: 0;
	left: 0;
}

.Cluster_inner {
	display: grid;
	grid-auto-columns: min-content;
	grid-auto-flow: column;
	width: 50%;
	margin: 0 auto;
	align-items: center;
	grid-template: repeat(2, auto) / repeat(2, auto);
	position: relative;
	top: 2.5rem;

	@include mq("sm") {
		top: 4rem;
	}

	@include mq("md") {
		top: 5rem;
		width: 35%;
	}

	@include mq("lg") {
		top: 11rem;
	}
}

.Cluster_imageWrapper {
	will-change: transform;
	transform-style: preserve-3d;
	transition: transform 0.9s cubic-bezier(0.51, 0.92, 0.24, 1.15);

	&:nth-child(1) {
		transform: scale(0.7) translate3d(-22%, 7%, 0);
	}
	&:nth-child(2) {
		transform: scale(1) translate3d(27%, -29%, 0);
		z-index: 2;
	}
	&:nth-child(3) {
		transform: scale(0.7) translate3d(-58%, -10%, 0);
		z-index: 1;
	}
	&:nth-child(4) {
		transform: scale(0.7) translate3d(25%, -85%, 0);
	}
}

:global(.isInView) {
	.Cluster_imageWrapper {
		&:nth-child(1) {
			transform: scale(1.1) translate3d(-60%, -20%, 0);
		}
		&:nth-child(2) {
			transform: scale(1.4) translate3d(5%, 18%, 0);
		}
		&:nth-child(3) {
			transform: scale(1.1) translate3d(5%, -36%, 0);
		}
		&:nth-child(4) {
			transform: scale(1.1) translate3d(67%, -15%, 0);
		}
	}
}
