@use "sass:math";
/* ------------------------------------ *\
		tools.mixins
\* ------------------------------------ */

@import "ui/styles/0-globals/globals.breakpoints";

// Media query mixin
// Min-width is default. For max-width, pass 'max' as second param.

/* Example use:

1. With min-width
	.foo {
			padding: 20px;

			@include mq('sm') {
					padding-top: 40px;
			}
	}

2. With max-width
	.foo {
			padding: 20px;

			@include mq('md', max) {
					padding-top: 10px;
			}
	}
*/
@mixin mq($width, $type: min) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);
		@if $type == max {
			$width: $width - 1px;
		}
		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}

@mixin mq-limit($width-start, $width-end, $type-start: min, $type-end: max) {
	@if map_has_key($breakpoints, $width-start) {
		$width-start: map_get($breakpoints, $width-start);
		$width-end: map_get($breakpoints, $width-end);

		@media only screen and (#{$type-start}-width: $width-start) and (#{$type-end}-width: $width-end) {
			@content;
		}
	}
}

// Breaks word when viewport is not wide enough

/* Example use:

1. Standard
	.foo {
		@include break-word();
	}

2. Within breakpoint
	.foo {
		@include mq('sm') {
			@include break-word();
		}
	}
*/
@mixin break-word() {
	word-break: break-all;
	word-break: break-word;
	hyphens: auto;
}

// hide placeholder text for all text inputs
@mixin hide-placeholder() {
	&::-webkit-input-placeholder {
		color: transparent; /* Chrome <=56, Safari < 10 */
	}

	&:-moz-placeholder {
		color: transparent; /* FF 4-18 */
	}

	&::-moz-placeholder {
		color: transparent; /* FF 19-51 */
	}

	&:-ms-input-placeholder {
		text-indent: -1000px; /* IE 10+ */
	}

	&::placeholder {
		color: transparent; /* Modern Browsers */
	}
}

/* Mixin for transitions
	* to be compliant with
	* media-prefers-reduced-motion
	*
*/
@mixin transition {
	@content;
	@media (prefers-reduced-motion: reduce) {
		transition-duration: 0;
	}
}

// Aspect ratio

/* Example use:

	.keep-me-at-aspect-ratio-16-9 {
		@include aspect-ratio(16, 9);
	}

*/

@mixin aspect-ratio($width, $height) {
	position: relative;
	width: 100%;
	display: block;
	overflow: hidden;
	padding-top: #{math.div($height, $width) * 100%};

	& > * {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
}

// Action
@mixin action {
	padding: 0;
	background: transparent;
	border-color: transparent;
	font-weight: var(--font-weight--semibold);
	cursor: pointer;
}

// Button
@mixin button {
	@include action;

	display: inline-block;
	padding: var(--spacing--xs) var(--spacing--lg);
	text-decoration: none;
	line-height: var(--line-height--lg);
	border-radius: var(--corner-size--lg);
	border: var(--border--md) solid transparent;
	text-align: center;
	min-width: 10em;

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	&___primary {
		color: var(--color-light);
		background: var(--color-primary);

		&:hover,
		&:focus {
			color: var(--color-light);
			background-color: var(--color-secondary);
		}
	}

	&___secondary {
		color: var(--color-primary);
		border-color: var(--color-primary);
		background-color: var(--color-light);

		&:hover,
		&:focus {
			color: var(--color-light);
			background-color: var(--color-primary);
		}
	}

	&___inactive,
	&:disabled {
		opacity: 0.3;
		pointer-events: none;

		&:hover,
		&:focus {
			transition: none;
		}
	}
}

// Input
@mixin input {
	@include paragraph;
	outline: none;
	padding: var(--spacing--sm) var(--spacing--base);

	&.hasError {
		border-color: var(--color-error);
		color: var(--color-error);
	}

	&::placeholder {
		color: currentColor;
	}

	// Used for text, email, password, number etc.
	&___string {
		width: 100%;
		border: var(--border--sm) solid transparent;
		background: var(--color-gradient--5);
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}

	&___string:focus,
	&___active {
		// outline-color: transparent;
		border-color: currentColor;
	}

	// Used for checkbox and radio
	&___choice {
		height: 1.5em;
		width: 1.5em;
	}

	// Used for textarea
	&___textarea {
		width: 100%;
		border: var(--border--sm) solid currentColor;
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}
}
